import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/pay/payok',
    name: 'payok',
    component: () => import('../views/pay/payok.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/webview',
    name: 'game',
    component: () => import('../views/webview.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/wage',
    name: 'wage',
    component: () => import('../views/wage.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/kbcheck',
    name: 'kbcheck',
    component: () => import('../views/kbcheck.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/kqcheck',
    name: 'kqcheck',
    component: () => import('../views/kqcheck.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/game',
    name: 'game',
    component: () => import('../views/game.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/wx',
    name: 'wx',
    component: () => import('../views/wx.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/hbpay',
    name: 'hbpay',
    component: () => import('../views/hbpay.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/custpay',
    name: 'custpay',
    component: () => import('../views/custpay.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/sinput',
    name: 'sinput',
    component: () => import('../views/sinput.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/fsinput',
    name: 'fsinput',
    component: () => import('../views/fsinput.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/quescheck',
    name: 'quescheck',
    component: () => import('../views/quescheck.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/agentcheck',
    name: 'agentcheck',
    component: () => import('../views/agentcheck.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/newstu',
    name: 'newstu',
    component: () => import('../views/newstu.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/pay/payments',
    name: 'payments',
    component: () => import('../views/pay/payments.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/aitalk',
    name: 'aitalk',
    component: () => import('../views/aitalk.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/pay/payment',
    name: 'payment',
    component: () => import('../views/pay/payment.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/depart_curriculum',
    name: 'depart_curriculum',
    component: () => import('../views/depart_curriculum.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/pay/newpay',
    name: 'newpay',
    component: () => import('../views/pay/newpay.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/scard',
    name: 'scard',
    component: () => import('../views/scard.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/pay/newpay2',
    name: 'newpay',
    component: () => import('../views/pay/newpay2.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/ar',
    name: 'ar',
    component: () => import('../views/ar.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/student/registration',
    name: 'registration_registration',
    component: () => import('../views/student/registration.vue'),
    meta: {
      hideTabbar: true
    }
  },
  {
    path: '/tools',
    name: 'tools',
    component: () => import('../views/tools.vue')
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('../views/mine.vue')
  },
  {
    path: '/dorm/list',
    name: 'dorm_list',
    component: () => import('../views/dorm/list.vue')
  },
  {
    path: '/dorm/inspections',
    name: 'inspections',
    component: () => import('../views/dorm/inspections.vue')
  },
  {
    path: '/student/search',
    name: 'search_student',
    component: () => import('../views/student/search.vue')
  },
  {
    path: '/student/trend_add',
    name: 'trend_add',
    component: () => import('../views/student/trend_add.vue')
  },
  {
    path: '/student/my_student',
    name: 'my_student',
    component: () => import('../views/student/my_student.vue')
  },
  {
    path: '/student/trends',
    name: 'trends',
    component: () => import('../views/student/trends.vue')
  },
  {
    path: '/student/leaves',
    name: 'leaves',
    component: () => import('../views/student/leaves.vue')
  },
  {
    path: '/dorm/dorms',
    name: 'dorms',
    component: () => import('../views/dorm/dorms.vue')
  },
  {
    path: '/dorm/dorm',
    name: 'dorm',
    component: () => import('../views/dorm/dorm.vue')
  },
  {
    path: '/school/banjis',
    name: 'banjis',
    component: () => import('../views/school/banjis.vue')
  },
  {
    path: '/school/kbs',
    name: 'kbs',
    component: () => import('../views/school/kbs.vue')
  },
  {
    path: '/school/dm',
    name: 'dm',
    component: () => import('../views/school/dm.vue')
  },
  {
    path: '/school/dm_check',
    name: 'dm_check',
    component: () => import('../views/school/dm_check.vue')
  },
  {
    path: '/school/kb_check',
    name: 'kb_check',
    component: () => import('../views/school/kb_check.vue')
  },
  {
    path: '/recruit/mylist',
    name: 'kb_check',
    component: () => import('../views/recruit/mylist.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history', // 使用 history 模式
})

export default router
